.chinese-chess {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  background: url("background.847ff816.jpeg") center / cover no-repeat;
  justify-content: center;
  align-items: center;
  padding: 100px 40px;
  display: flex;
}

.chinese-chess .chess-men {
  position: fixed;
}

.chinese-chess .chess-men .btn-start {
  width: 80px;
  height: 20px;
  text-align: center;
  color: #000;
  cursor: pointer;
  background: #d8bb8b;
  border-radius: 8px;
  margin-left: -40px;
  padding: 4px;
  position: fixed;
  bottom: 10px;
  left: 50%;
}

.chinese-chess .chess-men .game-modal .game-modal-mask {
  z-index: 1000;
  background: #0009;
  position: fixed;
  inset: 0;
}

.chinese-chess .chess-men .game-modal .game-modal-content {
  width: 200px;
  height: 300px;
  z-index: 1000;
  background: #9f7930;
  margin-top: -150px;
  margin-left: -100px;
  padding: 12px;
  position: fixed;
  top: 50%;
  left: 50%;
}

.chinese-chess .chess-men .game-modal .game-modal-content .game-item {
  height: 30px;
  align-items: center;
  display: flex;
}

.chinese-chess .chess-men .game-modal .game-modal-content .game-btn {
  width: 60px;
  height: 20px;
  text-align: center;
  cursor: pointer;
  background: #6a3a3a;
  border-radius: 8px;
  margin-left: -30px;
  padding: 4px;
  position: absolute;
  bottom: 12px;
  left: 50%;
}

html, body {
  margin: 0;
  padding: 0;
}

/*# sourceMappingURL=index.c8755b9b.css.map */
